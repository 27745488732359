<template>
  <div class="card w-100">
    <div class="card-body">
      <h5 class="card-title">Statistiken</h5>
      <div class="statisics">
        <div>
          <small class="text-muted">Veröffentlicht am</small>
          <p class="h5">{{ formatDate(statisics.releaseDate) }}</p>

<!--          <small class="text-muted">Blöcke verbaut</small>-->
<!--          <p class="h5">{{ statisics.blocks }} Blöcke</p>-->

          <small class="text-muted">Geschafft von</small>
          <p class="h5">{{ statisics.completed }}% / 100% </p>

          <small class="text-muted">Favoriten</small>
          <p class="h5">{{ statisics.favorites }} Favorit/en</p>

          <small class="text-muted">Maximale Kristalle</small>
          <p class="h5">{{ statisics.maxCrystals }} Kristall/e </p>

          <small class="text-muted">Tagessieger</small>
          <p class="h5">{{ getPotd(statisics.wasPotd, statisics.wasPotdTime * 1000) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/utils/helper";

export default {
  name: "Stats",
  props: ['statisics'],
  methods: {
    formatDate(timestamp) {
      return Helper.formatDate((timestamp / 1000));
    },
    getDay(timestamp) {
      let d = new Date();
      d.setTime(timestamp);
      return d.getDate().toString().padStart(2, '0') + '.'
          + (d.getMonth() + 1).toString().padStart(2, '0') + '.'
          + d.getFullYear();
    },
    getPotd(potd, time) {
      if (!potd) {
        return "Kein Tagessieger";
      }
      return 'Tagessieger vom ' + this.getDay(time);
    }
  },
}
</script>

<style scoped>
.statisics {
  float: left;
  text-align: left;
}

.statisics p {
  margin-bottom: 0.4rem;
}
</style>
