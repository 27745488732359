<template>
  <div v-if="!loaded && !error" class="mt-5 text-center">
    <Loader></Loader>
  </div>
  <div v-if="error" class="mt-5 text-center">
    <h1>Dieses JnR existiert nicht!</h1>
  </div>
  <div v-if="loaded" class="container text-center">
    <h1>{{ data.name }}</h1>
    <div class="row">
      <div class="col-lg-7 mb-3">
        <TopList class="mb-3" title="Top 25 [Alle]" max-items="25" :players="data.topTimes.allTime"></TopList>
        <TopList v-if="data.topTimes.hasOwnProperty('friends') && data.topTimes.friends.length > 0" class="mb-3" title="Top 25 [Freunde]" max-items="25"
                 :players="data.topTimes.friends"></TopList>
        <Votes class="mb-3" ratingName="Allgemeine Bewertung" :rating="data.rating.all"></Votes>
        <Votes v-if="data.rating.hasOwnProperty('self') && data.rating.self.length > 0" class="mb-3" ratingName="Deine Bewertung" :rating="data.rating.self"></Votes>
      </div>
      <div class="col-lg-5 mb-3">
        <Join class="mb-3" :jnr-id="this.id"></Join>
        <User class="mb-3" :creator="data.stats.creator"></User>
        <Stats class="mb-3" :statisics="data.stats"></Stats>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import TopList from "@/components/JnrOverview/TopList";
import Stats from "@/components/JnrOverview/Stats";
import Votes from "@/components/JnrOverview/Votes";
import User from "@/components/JnrOverview/User";
import Join from "../components/JnrOverview/Join";
import {env} from "@/environment/env";

export default {
  name: "JnrOverview",
  components: {Join, User, Votes, Stats, TopList, Loader},
  props: [],
  created() {
    fetch(env.TIMOLIA_API_URL + '/jumpworld/jnr/' + this.id + '')
        .then(response => response.json())
        .then(data => {
          this.loaded = true;
          this.data = data;
        }).catch((error) => {
          console.log(error); this.error = true });
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      error: false,
      data: {}
    }
  }
}
</script>

<style scoped>

</style>
