<template>
  <div class="row w-100 m-0">
    <div class="col col-vote"><img class="voteImage" :src="'https://www.timolia.de/media/minecraft/1.16.5/' + voteType.toLowerCase() + '.png'" :alt="voteType"></div>
    <div class="col col-vote"><img class="voteImage" :src="getIcon(rating, 5)" :alt="voteType"></div>
    <div class="col col-vote"><img class="voteImage" :src="getIcon(rating, 4)" :alt="voteType"></div>
    <div class="col col-vote"><img class="voteImage" :src="getIcon(rating, 3)" :alt="voteType"></div>
    <div class="col col-vote"><img class="voteImage" :src="getIcon(rating, 2)" :alt="voteType"></div>
    <div class="col col-vote"><img class="voteImage" :src="getIcon(rating, 1)" :alt="voteType"></div>
    <div class="col col-vote"><span style="font-size: 1.5rem">{{ rating.toFixed(2) }}</span></div>
  </div>
</template>

<script>
export default {
  name: "Vote",
  props: ['voteType', 'rating'],
  methods: {
    getIcon(rating, position) {
      const imageBase = 'https://www.timolia.de/media/minecraft/1.16.5/%image%.png'
      const imageColor = {5: 'green', 4: 'lime', 3: 'yellow', 2: 'orange', 1: 'red'}

      let max = position + 0.49;
      let min = position - 0.5;

      if (position === 1) {
        min = 0.0001;
      }

      if (position === 5) {
        max = 5;
      }

      if (rating >= min && rating <= max) {
        return imageBase.replace('%image%', imageColor[position] + '_terracotta');
      }

      return imageBase.replace('%image%', imageColor[position] + '_stained_glass');
    }
  }
}
</script>

<style scoped>
.col-vote {
  margin: 0;
  padding: 0;
  align-self: center;
}

.voteImage {
  height: 64px;
}

@media (max-width: 576px) {
  .voteImage {
    height: 40px;
  }
}
</style>
