<template>
  <div class="card w-100">
    <div class="card-body">
      <div>
        <h4>ID: {{jnrId}}</h4>
        <button class="btn btn-outline-warning mb-2" disabled>Beitreten</button><br>
        <span><b>Dieses Feature kommt bald!</b></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Join",
  props: ['jnrId']
}
</script>

<style scoped>

</style>
