<template>
  <div class="card w-100">
    <div class="card-body">
      <h5 class="card-title">
        Erbauer: <span :style="'color: #' + creator.color + '!important;'">{{creator.username}}</span>&nbsp;
       </h5>
<!--      <span class="badge rounded-pill bg-primary" :style="'background-color: #' + creator.color + '!important;'">-->
<!--        <span style="text-transform:capitalize">{{ creator.rank }}</span>-->
<!--      </span>-->
      <div class="statisics mt-3">
        <div>
          <img :src="'https://visage.surgeplay.com/full/256/' + creator.uuid" alt="Creator Image"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  props: ['creator']
}
</script>

<style scoped>

</style>
