<template>
  <div class="card w-100">
    <div class="card-body">
      <h5 class="card-title">{{ ratingName }} (⌀ {{getOverallRating()}})</h5>
      <div class="pt-2">
        <Vote voteType="DIAMOND" :rating="rating.creativity"></Vote>
        <Vote voteType="FIREWORK_ROCKET" :rating="rating.jumps"></Vote>
        <Vote voteType="ORANGE_TULIP" :rating="rating.optics"></Vote>
      </div>
    </div>
  </div>
</template>

<script>
import Vote from "@/components/JnrOverview/Vote";
export default {
  name: "Votes",
  components: {Vote},
  props: ['ratingName', 'rating'],
  methods: {
    getOverallRating() {
      return ((this.rating.creativity + this.rating.jumps + this.rating.optics) / 3).toFixed(2);
    }
  }
}
</script>

<style scoped>

</style>
