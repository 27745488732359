export default class Helper {
    public static formatDate(timestamp: number): string {
        const d = new Date();
        d.setTime(timestamp * 1000);
        return d.getDate().toString().padStart(2, '0') + '.'
            + (d.getMonth() + 1).toString().padStart(2, '0') + '.'
            + d.getFullYear() + ' '
            + d.getHours().toString().padStart(2, '0') + ':'
            + d.getMinutes().toString().padStart(2, '0') + ':'
            + d.getSeconds().toString().padStart(2, '0');
    }
}
