<template>
  <div class="card w-100">
    <div class="card-body">
      <h5 class="card-title">{{title}}</h5>
      <div class="table-responsive">
        <table class="table text-start">
          <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Spieler</th>
            <th scope="col">Zeit</th>
            <th scope="col">Fails</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(player, index) in players" :key="player.id">
            <th scope="row">{{index + 1}}</th>
            <td><img :src="'https://cravatar.eu/helmhead/' + player.name + '/32'" alt="Player Head"/> {{ player.name }}</td>
            <td>{{ getPlayerTime(player.time) }}</td>
            <td>{{ player.fails }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopList",
  props: ['title', 'players', 'maxItems'],
  methods: {
    pad(n, z) {
      z = z || 2;
      return ('00' + n).slice(-z);
    },
    getPlayerTime(time) {
      time = time * 50;
      let timeString = "";

      let ms = time % 1000;
      time = (time - ms) / 1000;
      let secs = time % 60;
      time = (time - secs) / 60;
      let mins = time % 60;
      let hrs = (time - mins) / 60;

      if (hrs !== 0) {
        timeString = this.pad(hrs) + ':';
      }

      timeString += this.pad(mins) + ':' + this.pad(secs) + '.' + this.pad(ms, 3);

      return timeString;
    },
  }
}
</script>

<style scoped>

</style>
